import React, { Fragment } from "react";

import Home from "./Home";
import AuditTraining from "./AuditTraining";
import AuditProcess from "./AuditProcess";
import Blog from "./Blog";
import Contact from "./Contact";
import MapMarker from "./MapMarker";

const sections = () => {
  return (
    <Fragment>
      <Home />
      <AuditTraining />
      <AuditProcess />
      <Blog />
      <Contact />
      {/* <MapMarker /> */}
    </Fragment>
  );
};

export default sections;
