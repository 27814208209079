import React, { Fragment } from "react";
import CookieConsent from "react-cookie-consent";

import { BrowserRouter, Route, Routes } from "react-router-dom";

// layout
import Header from "./Header";
import Footer from "./Footer";
import Sections from "../sections";

// Pages
import Audits from "../pages/Audits";

const Layout = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Sections />} />
            <Route path="audits" element={<Audits />} />
          </Routes>
        </main>
        <Footer />
        <CookieConsent
          debug={true}
          location="bottom"
          buttonText="Accept"
          enableDeclineButton
          declineButtonText="Decline"
          style={{ background: "#05304a" }}
          declineButtonStyle={{ backgroundColor: "#9D621A", color: "#fff" }}
          buttonStyle={{ backgroundColor: "#136f40", color: "#fff" }}
        >
          This website uses cookies to enhance your user experience.
        </CookieConsent>
      </Fragment>
    </BrowserRouter>
  );
};

export default Layout;
