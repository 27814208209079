import React, { useState } from "react";

// data
import { socials } from "../navigation";

// components
import Link from "../../components/Link";
import { CookiePolicy } from "./CookiePolicy";

const Footer = () => {
  const [cookieModal, setCookieModal] = useState(false);

  const showCookiePolicy = () => setCookieModal(!cookieModal);
  return (
    <footer className="">
      <div className="container text-light pt-5">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
            <div className="footer-title">
              <h6>About Us</h6>
            </div>
            <div className="footer-content">
              <p>
                <small className="text-muted">
                  <div className="my-3">
                    {" "}
                    <h5>noun • ex·tro·py • ˈɛkstrəpi/</h5>{" "}
                    <h5>
                      an 'evolving framework of values and standards for continuously improving the human condition'
                    </h5>{" "}
                    <p>---- Max More (2003). Principles of Extropy (Version 3.11)</p>{" "}
                  </div>
                </small>
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>Quick Links</h6>
            </div>
            <div className="footer-content">
              <ul className="list-group quick-links">
                <li>
                  <Link target="home" offset={-120}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link target="services">Services</Link>
                </li>
                <li>
                  <Link target="education">Education</Link>
                </li>
                <li>
                  <Link target="blog">Blog</Link>
                </li>
                <li>
                  <Link target="contact">Contact</Link>
                </li>
                <li>
                  <a href="#home" onClick={showCookiePolicy}>
                    {" "}
                    Cookie Policy
                  </a>
                  <CookiePolicy modal={cookieModal} toggle={showCookiePolicy} />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>Contact Us</h6>
            </div>
            <div className="footer-content">
              <p className="text-muted">
                <small>
                  <u>Address :</u>{" "}
                  <address style={{ display: "inline" }}>
                  John Eccles House, Robert Robinson Avenue,Oxford Science Park, Oxford - OX4 4GP, United Kingdom
                  </address>
                </small>
              </p>
              <p className="text-muted">
                <small>
                  <u>Phone :</u> +44 (0) 1865 338228
                </small>
              </p>
              <p className="text-muted">
                <small>
                  <u>E-mail :</u> info@extropy.io
                </small>
              </p>
              <div className="social-media mt-4">
                {socials.map((social) => (
                  <a href={social.link} target="_blank" rel="noopener noreferrer" className="text-light">
                    <i className={"fab " + social.icon + " mr-4"} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer pt-3 pb-3 text-center">
        <small>
          © Extropy.io 2022 - All Right Reserved.
        </small>
      </div>
    </footer>
  );
};

export default Footer;
