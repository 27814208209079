import React from "react";

// components
import Section from "../../components/Section";

const AuditTraining = () => {
  return (
    <Section id="auditTraining">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Audit Training Course </span>
          </h2>
          <div className="section-subtitle mr-auto ml-auto">
          <h2> Train to be an auditor with our online course</h2>  
          <h3>Our next cohort starts on 9th September</h3>
          Apply <a href="https://cryptoaudit.ninja" > here for tickets</a>
        </div>
        </div>
       
      </div>
      <div className="container-fluid pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            Preparing for an <span>Audit.</span>
          </h2>
          <div className="section-subtitle m-auto pb-3">
            <p>
              If you decide to audit your contract with Extropy, we recommend some steps beforehand to ensure you make
              the most of our auditing service.
            </p>
          </div>
          <div class="container row section-subtitle m-auto">
            <div class="col-4">
              <i class="fas fa-code p-3 text-adjacent-1" style={{ fontSize: "3rem" }}></i>
              <p>Write clean code with a consistent code style</p>
            </div>
            <div class="col-4">
              <i class="fas fa-cubes p-3 text-primary" style={{ fontSize: "3rem" }}></i>
              <p>Use standard libraries where possible</p>
            </div>
            <div class="col-4">
              <i class="fas fa-vial p-3 text-secondary" style={{ fontSize: "3rem" }}></i>
              <p>Include a test suite (ideally 100% code coverage)</p>
            </div>
            <div class="col-4">
              <i class="fas fa-book p-3 text-adjacent-2" style={{ fontSize: "3rem" }}></i>
              <p>Document the functions of your public API (at least)</p>
            </div>
            <div class="col-4">
              <i class="fas fa-coins p-3 text-complement" style={{ fontSize: "3rem" }}></i>
              <p>For token issuance document the procedure</p>
            </div>
            <div class="col-4">
              <i class="fas fa-users p-3 text-primary" style={{ fontSize: "3rem" }}></i>
              <p>Include end-user documentation where relevant</p>
            </div>
          </div>
          {/* <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li>Document your protocol and release process</li>
              <li></li>
              <li></li>
            </ul> */}
        </div>
      </div>
    </Section>
  );
};

export default AuditTraining;
