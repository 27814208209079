export const menu = [
  {
    title: "Overview",
    target: "overview",
    type: "anchor",
  },
  {
    title: "Audits",
    path: "audits",
    type: "page"
  },
  {
    title: "Security News",
    target: "Blog",
    type: "anchor",
  },
  {
    title: "Contact",
    target: "contact",
    type: "anchor"
  },
];

export const socials = [
  {
    link: "https://twitter.com/extropy",
    icon: "fa-twitter",
  },
  {
    link: "https://www.linkedin.com/company/extropy-io-ltd/",
    icon: "fa-linkedin",
  },
  {
    link: "https://extropy-io.medium.com/",
    icon: "fa-medium",
  },
  {
    link: "https://t.me/joinchat/AAAAAE4-sCvat80zz8CntQ",
    icon: "fa-telegram",
  },
  {
    link: "https://github.com/ExtropyIO",
    icon: "fa-github",
  },
];
